import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Arrow } from 'app/components/GalleryCategories/Arrow'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useState } from 'react'

import { Itinerary, Props as ItineraryProps } from './Itinerary'

export interface Props {
  list: ItineraryProps[]
}

export const Itineraries = memo(function Itineraries({ list }: Props) {
  if (!list) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    centered: true,
    created: (s) => {
      list.length < 3 ? s.destroy() : null
    },
    duration: 1500,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    slidesPerView: 2,
    spacing: 80,
    breakpoints: {
      '(max-width: 1023px)': {
        slidesPerView: 1.2,
        spacing: 12,
      },
    },
  })

  return (
    <Container>
      <FadeInUp>
        <Slider
          className={`keen-slider${list.length < 3 ? ' no-slider' : ''}`}
          ref={sliderRef}
        >
          {list.map((item, index) => (
            <Slide key={index} className="keen-slider__slide">
              <Itinerary isActive={currentSlide === index} {...item} />
            </Slide>
          ))}
        </Slider>
        {list.length > 2 ? (
          <Arrows row space="between">
            <Arrow onClick={(e) => e.stopPropagation() || slider.prev()} />
            <Arrow
              direction="R"
              onClick={(e) => e.stopPropagation() || slider.next()}
            />
          </Arrows>
        ) : null}
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  margin: 9rem auto 12.5rem;
  position: relative;

  @media (max-width: 1023px) {
    margin: 7.5rem auto;
  }
`

const Slider = styled.div`
  &.no-slider {
    padding: 0 5.833vw;
    > div {
      width: calc(50% - 120px) !important;
      min-width: calc(50% - 120px) !important;
      margin: 0 3.75rem;
    }
    > div > div {
      transform: scale(1) !important;
    }
  }

  @media (max-width: 1023px) {
    &.no-slider {
      > div {
        width: 100% !important;
        min-width: 100% !important;
        margin: 0;
      }
    }
  }
`

const Slide = styled.div`
  padding-top: 1.625rem;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  right: 12.917vw;
  left: 12.917vw;
  z-index: 3;

  > div {
    width: 5.625rem;
    height: 5.625rem;
    border: 0;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    margin-left: 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`
