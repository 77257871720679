import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Download } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  bookButtonURL?: string
  description?: string
  file?: string
  fileLabel?: string
  isActive?: boolean
  image?: ImageProps
  info?: { [key: string]: any }[]
  label?: string
  languageCode: string
  title?: string
}

export const Itinerary = memo(function Itinerary({
  bookButtonURL,
  description,
  file,
  fileLabel,
  isActive,
  image,
  info,
  label,
  languageCode,
  title,
}: Props) {
  return (
    <Container className={isActive ? 'active' : undefined}>
      <Background>
        {label ? <Label>{label}</Label> : null}
        {image ? <LazyLoadImage effect="blur" {...image} /> : null}
        {title ? <Title>{title}</Title> : null}
      </Background>
      <Wrapper>
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {info
          ? info.map((item, index) => (
              <Info key={index} row wrap>
                {item.label ? <InfoLabel>{`${item.label}:`}</InfoLabel> : null}
                {item.description ? (
                  <InfoText>{item.description}</InfoText>
                ) : null}
              </Info>
            ))
          : null}
        {bookButtonURL || file ? (
          <Buttons dial={4} row space="between" wrap>
            {bookButtonURL ? (
              <Button
                label={useVocabularyData(
                  'book-and-discover-itinerary',
                  languageCode,
                )}
                target="_blank"
                URL={bookButtonURL}
              />
            ) : null}
            {file ? (
              <DownloadButton href={file} rel="noopener" target="_blank">
                <Download />
                {fileLabel}
              </DownloadButton>
            ) : null}
          </Buttons>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  height: 100%;
  border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  transform: scale(0.8);
  transition: 0.6s ease-in-out;
  &.active {
    transform: scale(1);
  }
  &:hover {
    cursor: move;
    cursor: grab;
  }

  @media (max-width: 1023px) {
    transform: none;
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding-bottom: 50%;
  position: relative;
  &:before {
    content: '';
    width: calc(100% + 1.25rem);
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.5)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
    position: absolute;
    top: -0.625rem;
    left: -0.625rem;
    z-index: 1;
  }

  img {
    width: calc(100% + 1.25rem);
    height: 100%;
    position: absolute;
    top: -0.625rem;
    left: -0.625rem;
  }

  @media (max-width: 1023px) {
    padding-bottom: 80%;
  }
`

const Label = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  border-radius: 1.625rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 1rem 1.3125rem;
  position: absolute;
  top: -2.25rem;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  text-transform: uppercase;
  z-index: 2;

  @media (max-width: 1023px) {
    right: 6.039vw;
    left: 6.039vw;
    transform: none;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  position: absolute;
  bottom: 3.125rem;
  right: 4.583vw;
  left: 4.583vw;
  text-transform: uppercase;
  z-index: 2;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
    bottom: 3rem;
    right: 6.039vw;
    left: 6.039vw;
  }
`

const Wrapper = styled.div`
  padding: 3.125rem 4.583vw 4.125rem;

  @media (max-width: 1023px) {
    padding: 3rem 6.039vw 3.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 2.5rem;

  @media (max-width: 1023px) {
    margin-bottom: 1.875rem;
  }
`

const Info = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-top: 0.375rem;
  text-transform: uppercase;
`

const InfoLabel = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  margin-right: 0.25rem;
`

const InfoText = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
`

const Buttons = styled(FlexBox)`
  a {
    margin-top: 2.5rem;
  }
`

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 1rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-right: 0.75rem;
  }
`
